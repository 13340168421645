<template>
  <div class="app-wrapper">
    <BaseNavigation />
    <DividerNavigation />
    <div class="p-2 mx-auto" v-if="webinar.data && !webinar.isLoading">
      <div style="width: 100%" class="pl-25">
        <b-button
          variant="outline-danger"
          block
          @click="$router.push('/kegiatan/jadwal')"
        >
          Lihat Jadwal
        </b-button>
      </div>
      <br />
      <div>
        <b-card
          v-for="item in webinar.data"
          :key="item.id"
          class="shadow-none border mb-1"
          no-body
        >
          <div class="d-flex p-1 border-bottom">
            <div>
              <div class="font-weight-bold">
                #KEGIATAN-{{ item.webijadwal_id.id }}
              </div>
              <!-- <b-badge variant="light-danger font-weight–light mt-25">
                Belum terverifikasi
              </b-badge> -->
            </div>
            <div class="ml-auto pointer">
              <b-button
                size="sm"
                class="bg-primary bg-lighten-1"
                @click="batalkanKegiatan(item.webijadwal_id.id)"
              >
                Cancel
              </b-button>
              <b-button
                size="sm"
                class="bg-warning bg-lighten-1"
                target="_blank"
                @click="sertifikatKegiatan(item.webijadwal_id.id)"
              >
                Sertifikat
              </b-button>
            </div>
          </div>
          <div class="p-1">
            <table>
              <tbody>
                <tr>
                  <td>Judul</td>
                  <td class="font-weight-bold">
                    : {{ item.webijadwal_id.webijadwal_title }}
                  </td>
                </tr>
                <tr>
                  <td>Urutan</td>
                  <td class="font-weight-bold">: {{ item.id }}</td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td class="font-weight-bold">: {{ item.created_at }}</td>
                </tr>
                <tr>
                  <td>Link Kegiatan</td>
                  <td class="font-weight-bold">
                    :
                    <!-- <a
                      :href="item.webijadwal_id.webijadwal_link"
                      target="_blank"
                      >Link Kegiatan</a
                    > -->
                    <b-link :href="link_kegiatan(item)" target="_blank"
                      >Link Kegiatan</b-link
                    >
                  </td>
                </tr>
                <tr>
                  <td>Link Zoom/Zoom ID</td>
                  <td class="font-weight-bold">
                    :
                    <!-- <a
                      :href="item.webijadwal_id.webijadwal_zoom_id"
                      target="_blank"
                      >Link Zoom</a
                    > -->
                    <b-link :href="link_zoom(item)" target="_blank"
                      >Link Zoom</b-link
                    >
                  </td>
                </tr>
                <tr>
                  <td>Nama Pendaftar</td>
                  <td class="font-weight-bold">
                    : {{ item.orang_id.orang_nama_lengkap }}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <b-embed
              v-if="item.webijadwal_id.webijadwal_link.includes('embed')"
              :src="item.webijadwal_id.webijadwal_link"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></b-embed>
          </div>
        </b-card>

        <div
          v-if="webinar.isLoading"
          class="d-flex justify-content-center mb-1"
        >
          <b-spinner label="Loading..." variant="danger" />
        </div>
      </div>
    </div>
    <!-- <div v-else class="p-2 mx-auto">
      <b-card
        class="shadow-none border mb-1 pointer mr-1 webinar-card"
        :img-src="webinarNotFound">
      </b-card>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable global-require */

import {
  BButton,
  BCard,
  BImg,
  BSpinner,
  BForm,
  BLink,
  BEmbed,
  BFormGroup,
  BFormFile,
  // BBadge,
} from "bootstrap-vue";
import BaseNavigation from "@/components/Base/BaseNavigation.vue";
import DividerNavigation from "@/components/Base/DividerNavigation.vue";
// import { required, email } from '@validations'
import apis from "@/api";
import storage from "@/helpers/storage";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BaseNavigation,
    DividerNavigation,
    BButton,
    BCard,
    BImg,
    BLink,
    BEmbed,
    BSpinner,
    BForm,
    BFormGroup,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    // BBadge,
  },
  data() {
    return {
      required,
      webinar: {
        data: null,
        isLoading: false,
        nextPageUrl: null,
      },
      tempFileBerkas: null,
      fileBerkas: null,
      toggleUploadBerkas: false,
    };
  },
  computed: {
    // webinarNotFound() {
    //   return require("@/assets/images/logo/404Images.jpeg");
    // },

    link_zoom() {
      return (item) => {
        if (item.webijadwal_id.webijadwal_zoom_id) {
          return item.webijadwal_id.webijadwal_zoom_id;
        } else {
          return "link zoom tidak ditemukan";
        }
      };
    },

    link_kegiatan() {
      return (item) => {
        if (item.webijadwal_id.webijadwal_link) {
          return item.webijadwal_id.webijadwal_link;
        } else {
          return "link kegiatan tidak di temukan";
        }
      };
    },
  },
  mounted() {
    document
      .getElementsByClassName("app-wrapper")[0]
      .addEventListener("scroll", this.scrollCallback);
  },
  created() {
    this.fetchSertifikatKegiatan();
  },
  methods: {
    viewPdf(url) {
      storage.setStorage("pdfUrl", url);
      this.$router.push({ path: "/pdfview" });
    },
    scrollCallback() {
      const element = document.getElementsByClassName("app-wrapper")[0];
      const { scrollTop } = element;
      const { scrollHeight } = element;
      const { clientHeight } = element;
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        if (!this.webinar.isLoading && this.webinar.nextPageUrl) {
          this.fetchSertifikatKegiatan(this.webinar.nextPageUrl);
        }
      }
    },
    fetchSertifikatKegiatan(url) {
      this.webinar.isLoading = true;
      apis.webinar
        .listRegisterWebinar(url)
        .then(({ data }) => {
          if (url) {
            this.webinar.data = this.webinar.data.concat(data.data);
          } else {
            this.webinar.data = data.data;
          }
          this.webinar.nextPageUrl = data.next_page_url;
        })
        .finally(() => {
          this.webinar.isLoading = false;
        });
    },

    batalkanKegiatan(id) {
      this.$swal({
        title: "Apakah kamu yakin?",
        text: "Setelah membatalkan webinar, anda tetap bisa mendaftar kembali",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Batalkan!",
        cancelButtonText: "Tutup",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$store.commit("app/UPDATE_LOADING_BLOCK", true);
            return apis.webinar.cancelWebinar(id);
          }
          return false;
        })
        .then((result) => {
          if (result) {
            this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
            this.fetchSertifikatKegiatan();
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Berhasil membatalkan pendaftaran webinar",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((error) => {
          this.errorHandler(
            error,
            "Gagal membatalkan pendaftaran webinar, silahkan coba lagi nanti"
          );
        });
    },

    sertifikatKegiatan(id) {
      this.webinar.isLoading = true;
      apis.webinar
        .sertifikatWebinar(id)
        .then(({ data }) => {
          return this.$router.push(
            `/sertifikat/${data.data.webinar_id}/kegiatan`
          );
        })
        .catch((error) => {
          this.errorHandler(
            error,
            "Gagal membatalkan pendaftaran kegiatan, silahkan coba lagi nanti"
          );
        })
        .finally(() => {
          this.webinar.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
